<template>
  <div>
    <div class="mb20">
      <h3>产品信息：</h3>
      <el-select
        v-model="form.productNo"
        placeholder="请选择产品型号"
        @change="getSelectProduct"
        filterable
        clearable
      >
          <el-option
            v-for="item in tableList"
            :key="item.productNo"
            :label="item.productModel"
            :value="item.productNo"
          >
        </el-option>
      </el-select>
    </div>
    <div class="mb20">
      <h3>已选产品信息：</h3>
      <el-table
        :data="newtableList"
        style="width: 77%">
        <el-table-column prop="productNo" label="产品编号" width="180"/>
        <el-table-column prop="productModel" label="产品名称" width="180"/>
        <el-table-column prop="productType" label="产品类型" width="180">
          <template slot-scope="{ row }">
            {{ $changeObj(PRODUCT_TYPE)[row.productType] }}
          </template>
        </el-table-column>
        <el-table-column prop="vehicleSpecification" label="车辆规格" width="180"/>
        <el-table-column prop="vehicleBrand" label="车辆品牌" width="180"/>
        <el-table-column prop="productModel" label="车辆型号" width="180"/>
        <el-table-column prop="createTime" label="创建时间" width="180"/>
      </el-table>
    </div>
    <div class="formItemBox">
      <h3>中控号：</h3>
      <el-input @keyup.native="form.udid = form.udid.replace(/[^\d]/ig,'')" v-model="form.udid" placeholder="请输入" class="mb20"/>
    </div>
    <div class="formItemBox">
      <h3>车牌号：</h3>
      <el-input @keyup.native="form.frameNo = form.frameNo.replace(/[^\d]/ig,'')" v-model="form.frameNo" placeholder="请输入" class="mb20"/>
    </div>
    <div class="formItemBox">
      <h3>车架号：</h3>
      <el-input @keyup.native="form.vinNo = form.vinNo.replace(/[^\d]/ig,'')" v-model="form.vinNo" placeholder="请输入" class="mb80"/>
    </div>
    <div>
      <el-button type="primary" class="mr20" @click="bicycleAddOrUpdate($route.query.status)">确认</el-button>
      <el-button @click="$router.push('/leaseholdMgmt/electricVehicle')">返回</el-button>
    </div>
  </div>
</template>

<script>
// import {bicycleList} from "@/api/facility"
import {PRODUCT_TYPE} from '@/js/constant'
import { getProductList } from "@/api/product";
import { bicycleAddOrUpdate ,bicycleDetail,bicycleChangeProduct} from "@/api/facility";

export default {
  data() {
    return {
      PRODUCT_TYPE,
      productList: [],
      options:[],
      form:{
        udid:'',
        productNo:'',
        frameNo:'',
        vinNo:'',
        bicycleNo:null
      },
      tableList:[],
      newtableList:[]
    }
  },
  mounted() {
    if (this.$route.query.status == "edit") {
      this.bicycleDetail();
    }else{
      this.getProductList();
    }
  },
  methods: {
    // 回显
    async bicycleDetail(){
      try{
        const params = {
          bicycleNo:this.$route.query.bicycleNo,
        };
        const res = await bicycleDetail(params);
        const mdata = res.mdata.bicycleDetail;
        this.form.productNo = mdata.productNo;
        this.form.frameNo = mdata.frameNo;
        this.form.udid = mdata.udid;
        this.form.vinNo = mdata.vinNo;
        this.getProductList();

      }catch(e){
        console.log(e,'e');
      }
    },
    // 产品型号
    async getProductList() {
      const params = {
        pageSize: 500,
        beginIndex: 0,
        productType: 2
      };
      const res = await getProductList(params);
      this.tableList = res.ldata;
      if(this.$route.query.status == "edit"){
        this.getTable(this.form.productNo);
      }
    },
     /** 获取选择的产品信息 */
    getSelectProduct() {
      this.getTable(this.form.productNo);
    },
    //获取table信息
    getTable(productNo) {
      const row = this.tableList.find(el => productNo == el.productNo);
      this.newtableList = [row];
    },
    // 确定按钮
    async bicycleAddOrUpdate(val){
        try{
          val === 'edit' ? this.form.bicycleNo = this.$route.query.bicycleNo : this.form.bicycleNo = null;
          const params = {...this.form};
          const res = await bicycleAddOrUpdate(params);
          if(val === 'edit'){
            this.$message.success('修改成功');
          }else{
            this.$message.success('添加成功');
          }
          this.$router.push('/leaseholdMgmt/electricVehicle');
        }catch(e){
          console.log(e,'e');
        }
    }
  },
  components: {

  }
}
</script>

<style scoped>
  .formItemBox{
    width:40%;
  }
  h3{
    margin-bottom:20px;
    background:#fff;
  }
</style>
